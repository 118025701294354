/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import deburr from 'lodash/deburr'
import '@mevis/mevisutils'

// Internet Explorer does not support slice for typed arrays
function sliceTypedArray(typedArray, start, end) {
    return Array.prototype.slice.call(typedArray, start, end)
}

export function hasBlacklistedSuffix(fileName, blacklist) {
    const tokens = fileName.split('.')
    if (tokens.length === 1 || (tokens[0] === '' && tokens.length === 2)) {
        // no suffix or hidden file
        return false
    }
    const suffix = tokens.pop().toLowerCase()
    return blacklist.indexOf(suffix) !== -1
}

export function isJpeg(uint8Array) {
    const magicMarker = sliceTypedArray(uint8Array, 6, 10)
    const JFIFMarker = [0x4a, 0x46, 0x49, 0x46] // "JFIF"
    const ExifMarker = [0x45, 0x78, 0x69, 0x66] // "Exif"
    return magicMarker.equals(JFIFMarker) || magicMarker.equals(ExifMarker)
}

export function isMlimage(uint8Array) {
    const magicMarker = sliceTypedArray(uint8Array, 0, 7)
    return magicMarker.equals([0x4d, 0x4c, 0x49, 0x6d, 0x61, 0x67, 0x65]) // "MLImage"
}

export function isHTML(uint8Array) {
    const magicMarker = sliceTypedArray(uint8Array, 0, 15)
    return magicMarker.equals([
        0x3c, 0x21, 0x44, 0x4f, 0x43, 0x54, 0x59, 0x50, 0x45, 0x20, 0x68, 0x74, 0x6d, 0x6c, 0x3e
    ]) // "<!DOCTYPE html>"
}

export function isPng(uint8Array) {
    const magicMarker = sliceTypedArray(uint8Array, 0, 8)
    return magicMarker.equals([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a])
}

export function stripExtension(filename) {
    return filename.replace(/\.[^/.]*$/, '')
}

export function toNiceAscii(s) {
    // eslint-disable-next-line no-control-regex
    return deburr(s).replace(/[^\x00-\x7f]/g, '')
}

export default {
    hasBlacklistedSuffix,
    isJpeg,
    isMlimage,
    isPng,
    stripExtension,
    toNiceAscii
}
